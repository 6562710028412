.form {
	background: $white;
	margin: 0 auto;

	&--registration {
		box-shadow: 0px 10px 20px rgba(26, 84, 130, 0.08);
		padding: 45px 70px 60px 80px;
		width: 700px;

		@media screen and (max-width: 1365px) {
			width: 540px;
			padding: 40px 50px 60px; }

		@media screen and (max-width: 1023px) {
			padding: 45px 50px 55px;
			order: 4; }

		@media screen and (max-width: 579px) {
			width: 100%;
			max-width: 400px;
			padding: 25px 25px 45px; } }

	&--modal {
		width: 100%;

		@media screen and (max-width: 579px) {
			max-width: 280px;
			margin: 0 auto; } }

	&__fields-wrap {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: 30px;

		@media screen and (max-width: 579px) {
			margin-bottom: 20px; } }

	&__field {
		&--error {
			@include placeholder("", $red);

			+ .form__text--error {
				display: block; } } }

	&__text {
		font: 18px $MuseosanscyrlR;
		position: absolute;
		display: inline-block;
		top: 15px;
		right: 0;

		@media screen and (max-width: 1365px) {
			font-size: 16px;
			top: 14px; }

		@media screen and (max-width: 767px) {
			font-size: 14px; }

		@media screen and (max-width: 579px) {
			font-size: 12px;
			@include placeholder(12px $MuseosanscyrlR, rgba(0, 0, 0, 0.5));
			top: 10px; }

		&--error {
			color: $red;
			display: none; } } }
