@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.wrapper {
		overflow-x: hidden; }

	.header {
		&__side {
			min-width: 960px;

			&--right {
				position: relative;
				height: 100%;
				min-height: 986px;
				overflow: hidden; }

			&--left {
				max-width: 50%; } }

		&__img {
			position: absolute;
			top: 0;
			right: -20%;
			width: auto;
			height: 100%; } }

	.img--header {
		width: auto;
		height: 100%; }

	.nav {
		&--header,
		&--footer {
			display: flex; } }

	.gallery {
		&__link {
			position: relative;
			width: 883px;
			overflow: hidden;

			&--small {
				width: 588px; } }

		&__item {
			position: absolute;
			width: 100%;
			height: auto;
			top: 50%;
			left: 0;
			transform: translateY(-50%); } }

	.social {
		display: flex;
		flex-flow: row wrap; }

	.wait-for-you__item {
		&:last-child {
			&:after {
				content: '';
				position: absolute;
				top: 35%;
				left: 57%;
				width: 103px;
				height: 137px;
				transform: translate(-50%, -50%);
				background: url("../img/puzzled.png") no-repeat;
				background-size: cover;
				z-index: 5; } } } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 1919px) {
	.header {
		&__side {
			min-width: 683px;

			&--right {
				min-width: 683px;
				min-height: 699px;
				width: 50%; } } }

	.nav {
		&__list--header {
			padding: 10px 10px 0 45px; } }

	.gallery {
		&__link {
			width: 625px;

			&--small {
				width: 416px; } } } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 1365px) {
	.header {
		&__side {
			min-width: 512px;

			&--right {
				min-height: 523px;
				width: 50%; }

			&--left {
				padding: 15px 35px 0 40px; } } }


	.nav:not(:last-child) {
		.nav__item--main {
			margin-right: 20px; } }

	.gallery {
		&__link {
			width: 475px;

			&--small {
				width: 316px; } } }

	.wait-for-you__item {
		&:last-child {
			&:after {
				width: 88px;
				height: 117px; } } } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 1023px) {
	.header {
		&__side {
			min-width: 0;

			&--right {
				height: 785px;
				width: 100%; }

			&--left {
				max-width: 768px; } } }

	.nav {
		&--footer {
			display: none; }

		&:not(:last-child) {
			.nav__item--main {
				margin-right: 30px; } } }

	.gallery {
		&__link {
			width: 354px;

			&--small {
				width: 236px; } } }

	.social {
		&__list {
			width: 100%; } }

	.wait-for-you__item {
		&:last-child {
			&:after {
				top: 38%; } } } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 767px) {
	.header {
		&__side {
			&--right {
				height: 480px; } } }

	.nav {
		&--header {
			display: none; } } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 579px) {
	.header {
		&__side {
			&--right {
				height: 330px; } } } }
