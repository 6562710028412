.modal {
	z-index: 100;
	left: 50%;
	transition: transform .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);

	@media screen and (max-width: 579px) {
		height: 100%; }

	&--write-us {
		position: absolute;
		width: 900px;
		top: 200px;
		margin-bottom: 150px;
		background: $white;
		padding: 90px 175px 100px;
		transform: translate(-50%, 0) scale(0);

		@media screen and (max-width: 1365px) {
			width: 780px;
			padding: 90px 120px 100px; }

		@media screen and (max-width: 1023px) {
			width: 646px;
			padding: 80px 85px;
			top: 95px;
			margin-bottom: 45px; }

		@media screen and (max-width: 767px) {
			width: 480px;
			padding: 80px 60px 60px;
			top: 50px;
			margin-bottom: 25px; }

		@media screen and (max-width: 579px) {
			width: 100%;
			padding: 90px 20px 35px;
			top: 0;
			height: auto;
			min-height: 100vh;
			margin-bottom: 0; } }

	&--answer {
		width: 850px;
		top: 50%;
		position: fixed;
		transform: translate(-50%, -50%) scale(0);
		padding: 165px 125px 145px;

		@media screen and (max-width: 1023px) {
			width: 710px;
			padding: 150px 100px 135px; }

		@media screen and (max-width: 767px) {
			width: 520px;
			padding: 170px 30px 115px; }

		@media screen and (max-width: 579px) {
			width: 100%;
			padding: 195px 30px 140px; }

		&:before {
			content: '';
			position: absolute;
			top: 80px;
			left: 145px;
			width: 221px;
			height: 164px;
			z-index: -1;

			@media screen and (max-width: 1023px) {
				top: 55px;
				left: 75px; }

			@media screen and (max-width: 579px) {
				top: 60px;
				left: 50%;
				transform: translateX(-50%); } }

		&-success {
			background: $darkBlue;

			&:before {
				background: url(../img/stars-blue.png) no-repeat;
				background-size: cover; } }

		&-error {
			background: $blue;

			&:before {
				background: url(../img/stars-gray.png) no-repeat;
				background-size: cover; } } }

	&--open-top {
		transform: translate(-50%, 0) scale(1); }

	&--open-center {
		transform: translate(-50%, -50%) scale(1); }

	&__subtitle {
		font: 16px $MuseosanscyrlR;
		text-align: center;
		display: inline-block;
		width: 100%;
		margin-bottom: 80px;

		@media screen and (max-width: 1023px) {
			margin-bottom: 55px; }

		@media screen and (max-width: 767px) {
			font-size: 14px;
			margin-bottom: 35px; }

		@media screen and (max-width: 579px) {
			font-size: 12px;
			margin-bottom: 25px; } }

	&__content {
		color: $white;
		font: 20px/32px $MuseosanscyrlR;
		text-align: center;
		max-width: 350px;
		margin: 0 auto;

		@media screen and (max-width: 1023px) {
			font-size: 18px;
			line-height: 28px; }

		@media screen and (max-width: 767px) {
			font-size: 16px;
			line-height: 26px; } }

	&__close {
		width: 72px;
		height: 72px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), inset 0 0 0 9px $white, inset 0 0 0 10px $lightGray;
		border-radius: 50%;
		background: $white;
		position: absolute;
		transition: background .25s ease-in, box-shadow .25s ease-in;
		top: -36px;
		right: -36px;
		cursor: pointer;
		display: inline-block;

		@media screen and (max-width: 1023px) {
			width: 50px;
			height: 50px;
			top: -25px;
			right: -25px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), inset 0 0 0 6px $white, inset 0 0 0 7px $lightGray; }

		@media screen and (max-width: 579px) {
			width: 40px;
			height: 40px;
			top: 20px;
			right: 50%;
			transform: translateX(50%);
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), inset 0 0 0 5px $white, inset 0 0 0 6px $lightGray; }

		&:hover,
		&:active {
			background: $blue;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), inset 0 0 0 5px $blue, inset 0 0 0 6px rgba(255, 255, 255, 0.2);

			&:before,
			&:after {
				background: $white; } }

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			height: 1px;
			width: 21px;
			background: $blue;
			transform-origin: top left;

			@media screen and (max-width: 1023px) {
				width: 15px; } }

		&:before {
			transform: rotate(45deg) translate(-50%, -50%); }

		&:after {
			transform: rotate(135deg) translate(-50%, -50%); } } }
