.timer {
	&__wrap {
		display: flex;

		@media screen and (max-width: 1023px) {
			justify-content: center; } }

	&__item {
		display: flex;
		flex-flow: column nowrap;
		padding-bottom: 15px;

		@media screen and (max-width: 767px) {
			padding-top: 10px;
			padding-bottom: 10px; }

		&:first-child {
			width: 140px;

			@media screen and (max-width: 1919px) {
				width: 105px; }

			@media screen and (max-width: 1365px) {
				width: 80px; }

			@media screen and (max-width: 1023px) {
				width: 100px;
				padding-left: 20px; }

			@media screen and (max-width: 767px) {
				width: 85px; }

			@media screen and (max-width: 579px) {
				width: 70px;
				padding-left: 15px; } }

		&:not(:first-child) {
			padding-left: 35px;
			width: 170px;

			@media screen and (max-width: 1919px) {
				padding-left: 25px;
				width: 125px; }

			@media screen and (max-width: 1365px) {
				padding-left: 20px;
				width: 100px; }

			@media screen and (max-width: 767px) {
				width: 85px; }

			@media screen and (max-width: 579px) {
				width: 70px;
				padding-left: 15px; } }

		&:not(:last-child) {
			border-right: 1px solid $lightGray;
			padding-right: 20px;

			@media screen and (max-width: 1919px) {
				padding-right: 10px; } }

		&:last-child {
			@media screen and (max-width: 1023px) {
				padding-right: 10px; } } }

	&__count {
		font: 80px $MuseosanscyrlSB;
		color: $blue;
		margin-bottom: 5px;

		@media screen and (max-width: 1919px) {
			font-size: 60px; }

		@media screen and (max-width: 1365px) {
			font-size: 50px;
			margin-bottom: 0; }

		@media screen and (max-width: 767px) {
			font-size: 40px;
			margin-bottom: 5px; }

		@media screen and (max-width: 579px) {
			font-size: 30px;
			margin-bottom: 10px; } }

	&__text {
		font: 26px $MuseosanscyrlL;
		color: $blueGray;

		@media screen and (max-width: 1919px) {
			font-size: 20px; }

		@media screen and (max-width: 1365px) {
			font-size: 16px; }

		@media screen and (max-width: 767px) {
			font-size: 14px; }

		@media screen and (max-width: 579px) {
			font-size: 12px; } }

	&__time-left {
		display: none; } }
