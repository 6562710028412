.title {
	font-family: $MuseosanscyrlB;

	&--main {
		font-size: 80px;
		color: $black;
		margin-bottom: 20px;

		@media screen and (max-width: 1919px) {
			font-size: 60px;
			margin-bottom: 15px; }

		@media screen and (max-width: 1365px) {
			font-size: 46px;
			margin-bottom: 10px; }

		@media screen and (max-width: 1023px) {
			text-align: center; }

		@media screen and (max-width: 767px) {
			font-size: 38px; }

		@media screen and (max-width: 579px) {
			font-size: 30px; } }

	&--timer {
		font: 22px $MuseosanscyrlL;
		color: $blueGray;
		margin-bottom: 25px;
		padding-left: 5px;

		@media screen and (max-width: 1919px) {
			font-size: 18px;
			margin-bottom: 15px; }

		@media screen and (max-width: 1365px) {
			font-size: 14px; }

		@media screen and (max-width: 1023px) {
			text-align: center;
			padding-left: 0; }

		@media screen and (max-width: 579px) {
			font-size: 12px; } }

	&--section {
		text-transform: uppercase;
		font-size: 60px;
		text-align: center;
		letter-spacing: .07em;
		color: $black;

		@media screen and (max-width: 1365px) {
			font-size: 46px; }

		@media screen and (max-width: 1023px) {
			font-size: 40px; }

		@media screen and (max-width: 767px) {
			font-size: 36px; }

		@media screen and (max-width: 579px) {
			font-size: 30px; } }

	&--program {
		text-align: left;
		padding-left: 5px;

		@media screen and (max-width: 579px) {
			text-align: center; } }

	&--parallel-sec {
		color: $blueGray;
		font-size: 28px;
		margin-bottom: 30px;

		@media screen and (max-width: 1365px) {
			font-size: 24px;
			margin-bottom: 25px; }

		@media screen and (max-width: 767px) {
			font-size: 22px; }

		@media screen and (max-width: 579px) {
			font-size: 20px;
			text-align: center; } }

	&--university {
		font-size: 210px;
		color: rgba(26, 84, 129, 0.28);
		margin-bottom: 30px;

		@media screen and (max-width: 1919px) {
			font-size: 150px;
			margin-bottom: 25px;
			padding-left: 0; }

		@media screen and (max-width: 1365px) {
			font-size: 112px; }

		@media screen and (max-width: 1023px) {
			font-size: 84px; }

		@media screen and (max-width: 767px) {
			font-size: 52px; }

		@media screen and (max-width: 579px) {
			font-size: 33px;
			margin-bottom: 15px; } }

	&--slider {
		color: $black;
		font-size: 30px;
		margin-bottom: 20px;

		@media screen and (max-width: 1919px) {
			font-size: 24px;
			margin-bottom: 15px; }

		@media screen and (max-width: 1365px) {
			font-size: 18px;
			margin-bottom: 12px; }

		@media screen and (max-width: 579px) {
			font-size: 16px;
			margin-bottom: 8px; } }

	&--how-it-was {
		color: $white;
		font-size: 80px;
		text-align: left;
		position: absolute;
		top: 175px;
		left: 0;

		@media screen and (max-width: 1919px) {
			font-size: 60px;
			top: 130px; }

		@media screen and (max-width: 1365px) {
			font-size: 46px;
			top: 100px; }

		@media screen and (max-width: 1023px) {
			font-size: 40px;
			top: 75px; }

		@media screen and (max-width: 767px) {
			position: static;
			font-size: 36px;
			margin-bottom: 45px; }

		@media screen and (max-width: 579px) {
			font-size: 30px;
			margin-bottom: 35px; } }

	&--registration {
		max-width: 935px;
		line-height: 90px;
		margin: 0 auto 25px;
		padding-left: 10px;

		@media screen and (max-width: 1365px) {
			max-width: 735px;
			line-height: 70px;
			padding-left: 0; }

		@media screen and (max-width: 1023px) {
			line-height: 60px;
			margin: 0 auto 20px;
			order: 1; }

		@media screen and (max-width: 767px) {
			line-height: 50px; }

		@media screen and (max-width: 579px) {
			line-height: 45px;
			padding: 0 5px; } }

	&--calendar {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		color: $red;
		font: 13px/20px $MuseosanscyrlSB;
		letter-spacing: .1em;
		text-align: center;
		text-transform: uppercase;
		padding: 14px 15px 10px;

		@media screen and (max-width: 579px) {
			padding: 25px 15px 20px; } }

	&--social {
		font-size: 14px;
		letter-spacing: .1em;
		text-transform: uppercase;
		color: $blueGray;
		margin-bottom: 45px;
		padding-left: 5px;

		@media screen and (max-width: 1365px) {
			font-size: 12px;
			margin-bottom: 35px;
			padding-top: 5px;
			padding-left: 0; }

		@media screen and (max-width: 579px) {
			text-align: center; } }

	&--modal {
		font-size: 38px;
		text-align: center;
		margin-bottom: 20px;

		@media screen and (max-width: 1023px) {
			margin-bottom: 15px; }

		@media screen and (max-width: 767px) {
			font-size: 32px;
			margin-bottom: 10px; }

		@media screen and (max-width: 579px) {
			font-size: 24px; } }

	&--modal-answer {
		font-size: 80px;
		color: $white;
		text-align: center;
		margin-bottom: 30px;

		@media screen and (max-width: 1023px) {
			font-size: 70px; }

		@media screen and (max-width: 767px) {
			font-size: 56px; }

		@media screen and (max-width: 579px) {
			font-size: 46px;
			margin-bottom: 20px; } } }
