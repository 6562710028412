.ps {
	overflow: hidden !important;
	overflow-anchor: none;
	touch-action: auto;

	&__rail-x {
		height: 0;
		bottom: 0;
		background: transparent;
		position: absolute;
		z-index: -2;

		@media screen and (max-width: 579px) {
			height: 10px; }

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			height: 1px;
			background: $blueGray;
			z-index: -3;
			display: none;

			@media screen and (max-width: 579px) {
				display: block; } } }

	&__thumb-x {
		background-color: $blue;
		border-radius: 15px;
		bottom: 2px;
		position: absolute;
		height: 0;

		@media screen and (max-width: 579px) {
			height: 6px; } } }

/* MS supports */
@supports (-ms-overflow-style: none) {
	.ps {
		overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ps {
		overflow: auto !important; } }
