.icon {
	&--logo,
	&--burger {
		width: 100%;
		height: 100%; }

	&--book {
		width: 122px;
		height: 122px;
		transform: translateX(25px);

		@media screen and (max-width: 1919px) {
			transform: translate(25px, -5px); }

		@media screen and (max-width: 1365px) {
			width: 105px;
			height: 86px;
			transform: translate(15px, -5px); } }

	&--education {
		width: 160px;
		height: 93px;
		transform: translateX(20px);

		@media screen and (max-width: 1919px) {
			transform: translate(20px, -5px); }

		@media screen and (max-width: 1365px) {
			width: 137px;
			height: 80px;
			transform: translate(10px, -5px); }

		@media screen and (max-width: 1023px) {
			transform: translate(15px, -5px); } }

	&--forum {
		width: 103px;
		height: 103px;
		transform: translateX(20px);

		@media screen and (max-width: 1919px) {
			transform: translate(20px, -5px); }

		@media screen and (max-width: 1365px) {
			width: 88px;
			height: 88px; }

		@media screen and (max-width: 1023px) {
			transform: translate(15px, 0); } }

	&--puzzled {
		width: 103px;
		height: 137px;
		transform: translate(25px, -20px);

		@media screen and (max-width: 1365px) {
			width: 88px;
			height: 117px; }

		@media screen and (max-width: 1023px) {
			transform: translate(25px, -15px); } }

	&--arrow {
		width: 8px;
		height: 15px;

		@media screen and (max-width: 767px) {
			width: 6px;
			height: 13px; } }

	&--fb {
		width: 13px;
		height: 34px;
		margin-left: 15px;

		@media screen and (max-width: 1365px) {
			width: 11px;
			height: 25px;
			margin-left: 10px; }

		@media screen and (max-width: 1023px) {
			margin-left: 0; } }

	&--yt {
		width: 36px;
		height: 26px;

		@media screen and (max-width: 1365px) {
			width: 32px;
			height: 22px; } }

	&--tw {
		width: 39px;
		height: 31px;

		@media screen and (max-width: 1365px) {
			width: 35px;
			height: 27px; } }

	&--tg {
		width: 37px;
		height: 31px;

		@media screen and (max-width: 1365px) {
			width: 32px;
			height: 26px; } }

	&--insta {
		width: 36px;
		height: 36px;

		@media screen and (max-width: 1365px) {
			width: 32px;
			height: 32px; } }

	&--triangle {
		width: 7px;
		height: 4px;
		margin-right: 15px; }

	&__item {
		&--arrow {
			stroke: $darkBlue;
			transition: stroke .25s ease-in; }

		&--social {
			fill: $darkBlue;
			transition: fill .35s ease-in; }

		&--developer {
			fill: $blue;
			transition: fill .25s ease-in; }

		&--triangle {
			fill: $darkBlue;
			transition: fill .35s ease-in; } } }
