.nav {
	&--header {
		@media screen and (max-width: 1023px) {
			flex: 1 0 auto; }

		@media screen and (max-width: 767px) {
			display: none; } }

	&--footer {
		width: 50%;
		padding-top: 5px;

		@media screen and (max-width: 1919px) {
			width: 440px;
			flex: 0 0 auto; }

		@media screen and (max-width: 1365px) {
			width: 365px; }

		@media screen and (max-width: 1023px) {
			display: none; } }

	&--burger {
		display: none;

		@media screen and (max-width: 767px) {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 115px 30px 60px;
			z-index: 30;
			background: $lightBlue;
			transform: scaleY(0);
			transform-origin: 100% 0%;
			transition: transform .4s cubic-bezier(0.390, 0.575, 0.565, 1.000); }

		&-open {
			transform: scaleY(1);
			transform-origin: 100% 0%; } }

	&--fixed {
		display: flex;
		flex: 1 0 auto;

		@media screen and (max-width: 1023px) {
			justify-content: flex-end; }

		@media screen and (max-width: 767px) {
			display: none; } }

	&__list {
		display: flex;

		&--header {
			flex-flow: row wrap;
			padding: 5px 30px 0 50px;

			@media screen and (max-width: 1919px) {
				padding: 10px 15px 0 45px; }

			@media screen and (max-width: 1365px) {
				padding: 5px 0 0 35px;
				margin-right: -20px; }

			@media screen and (max-width: 1023px) {
				margin-right: 0;
				height: 100%;
				align-items: center;
				justify-content: flex-end; } }

		&--footer {
			flex-flow: column wrap;
			align-content: flex-start;
			max-height: 200px;

			@media screen and (max-width: 1919px) {
				max-height: 170px; } }

		&--fixed {
			padding-left: 80px;

			@media screen and (max-width: 1919px) {
				padding-left: 60px; }

			@media screen and (max-width: 1365px) {
				padding-left: 50px; }

			@media screen and (max-width: 1023px) {
				padding-left: 30px; } }

		&--burger {
			display: block; } }

	&__item {
		&--header {
			&:not(:last-child) {
				margin-bottom: 20px;

				@media screen and (max-width: 1919px) {
					margin-bottom: 15px; }

				@media screen and (max-width: 1365px) {
					margin-bottom: 10px; }

				@media screen and (max-width: 1023px) {
					margin-bottom: 0; } } }

		&--footer {
			margin-bottom: 35px;
			width: 285px;

			@media screen and (max-width: 1919px) {
				width: 195px;
				margin-bottom: 25px; }

			@media screen and (max-width: 1365px) {
				width: 170px;
				margin-bottom: 23px; } }

		&--burger {
			margin-bottom: 30px; }

		&--main {
			&:not(:last-child) {
				margin-right: 50px;

				@media screen and (max-width: 1919px) {
					margin-right: 40px; }

				@media screen and (max-width: 1365px) {
					margin-right: 30px; } } } }

	&__link {
		font-family: $MuseosanscyrlB;
		color: $darkBlue;
		transition: color .35s ease-in;

		&:hover {
			color: $red; }

		&--main {
			font-size: 20px;
			line-height: 24px;

			@media screen and (max-width: 1919px) {
				font-size: 16px;
				line-height: 19px; }

			@media screen and (max-width: 1365px) {
				font-size: 14px;
				line-height: 17px; } }

		&--footer {
			font-size: 26px;
			line-height: 31px;
			display: inline-block;

			@media screen and (max-width: 1919px) {
				font-size: 20px;
				line-height: 24px; }

			@media screen and (max-width: 1365px) {
				font-size: 18px; } }

		&--burger {
			font-size: 24px;
			line-height: 29px;
			letter-spacing: .05em;
			text-align: center;
			text-transform: uppercase; } } }
