.map {
	height: 985px;

	@media screen and (max-width: 1919px) {
		height: 700px; }

	@media screen and (max-width: 1365px) {
		height: 525px; }

	@media screen and (max-width: 1023px) {
		order: 2;
		height: 395px; }

	@media screen and (max-width: 767px) {
		height: 350px; }

	@media screen and (max-width: 579px) {
		height: 300px; }

	&__frame {
		width: 100%;
		height: 100%; } }
