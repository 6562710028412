.slick {
	&-track {
		display: flex; }

	&-arrow {
		font-size: 0; } }

#slider-univer {
	.slick-list {
		padding: 0 15px;
		margin: 0 -15px;

		@media screen and (max-width: 579px) {
			padding: 0 20px;
			margin: 0 -20px; } }

	.slick-track {
		transform: translate3d(0, 0, 0) !important;
		position: relative;
		height: 725px;
		width: 100% !important;

		@media screen and (max-width: 1919px) {
			height: 520px; }

		@media screen and (max-width: 1365px) {
			height: 395px; }

		@media screen and (max-width: 1023px) {
			height: 615px; }

		@media screen and (max-width: 579px) {
			height: 470px; } }

	.slider__img {
		animation: slide-right-hide .65s ease-in-out both; }

	.slick-active .slider__img {
		animation: slide-right-show .65s ease-in-out both; }

	.slider__info {
		animation: slide-left-hide .65s ease-in-out both; }

	.slick-active .slider__info {
		animation: slide-left-show .65s ease-in-out both; } }

#slider-gallery {
	.slick-slide {
		transition: opacity .5s ease-in-out; } }

@keyframes slide-right-show {
	0% {
		transform: translateX(-100%);
		opacity: 0; }

	100% {
		transform: translateX(0);
		opacity: 1; } }

@keyframes slide-left-show {
	0% {
		transform: translateX(100%);
		opacity: 0; }

	100% {
		transform: translateX(0);
		opacity: 1; } }

@keyframes slide-right-hide {
	0% {
		transform: translateX(0);
		opacity: 1; }

	100% {
		transform: translateX(100%);
		opacity: 0; } }

@keyframes slide-left-hide {
	0% {
		transform: translateX(0);
		opacity: 1; }

	100% {
		transform: translateX(-100%);
		opacity: 0; } }
