.wait-for-you {
	padding: 110px 0 100px;

	@media screen and (max-width: 1919px) {
		padding: 115px 0 95px; }

	@media screen and (max-width: 1365px) {
		padding: 105px 0 90px; }

	@media screen and (max-width: 1023px) {
		padding: 105px 0 65px; }

	@media screen and (max-width: 767px) {
		padding: 65px 0 60px; }

	&__wrap {
		display: flex;

		@media screen and (max-width: 1023px) {
			flex-flow: row wrap; }

		@media screen and (max-width: 579px) {
			flex-flow: row nowrap;
			overflow-x: scroll;
			position: relative; } }

	&__item {
		width: 325px;
		padding: 75px 20px 25px;
		position: relative;

		@media screen and (max-width: 1365px) {
			width: 245px;
			padding: 70px 15px 30px; }

		@media screen and (max-width: 1023px) {
			width: 50%;
			padding: 70px 15px 5px;
			margin-bottom: 55px; }

		@media screen and (max-width: 767px) {
			margin-bottom: 40px; }

		@media screen and (max-width: 579px) {
			width: 220px;
			flex: 0 0 auto;
			padding: 70px 10px 5px;
			margin-bottom: 35px; }

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 25px;
			width: 220px;
			height: 165px;
			background: url("../img/stars-gray.png") no-repeat;
			background-size: cover;
			z-index: -1;

			@media screen and (max-width: 1365px) {
				top: -2px;
				left: 12px;
				width: 192px;
				height: 158px; }

			@media screen and (max-width: 1023px) {
				left: 70px; }

			@media screen and (max-width: 767px) {
				left: 20px; }

			@media screen and (max-width: 579px) {
				left: 0; } }

		&:not(:last-child) {
			border-right: 1px solid $lightGray;

			@media screen and (max-width: 579px) {
				border: none;
				margin-right: 20px; } }

		&:first-child,
		&:nth-child(2) {
			&:after {
				content: '';
				position: absolute;
				border-bottom: 1px solid $lightGray;

				@media screen and (max-width: 1023px) {
					bottom: -25px;
					width: 330px;
					height: 1px; }

				@media screen and (max-width: 767px) {
					bottom: -20px;
					width: 240px; }

				@media screen and (max-width: 579px) {
					display: none; } } }

		&:first-child {
			&:after {
				@media screen and (max-width: 1023px) {
					left: 0; } } }

		&:nth-child(2) {
			@media screen and (max-width: 1023px) {
				border-right: none;

				&:after {
					right: 0; } } } }

	&__img {
		width: 160px;
		height: 160px;
		border-radius: 50%;
		display: block;
		background: $lightBlue;
		box-shadow: 0 0 0 27px $white, 0 0 0 28px $lightGray;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 50px;

		@media screen and (max-width: 1365px) {
			width: 137px;
			height: 137px;
			box-shadow: 0 0 0 23px $white, 0 0 0 24px $lightGray;
			margin: 0 auto 40px; }

		@media screen and (max-width: 767px) {
			margin: 0 auto 50px; } }

	&__span {
		display: block;
		color: $black;
		text-align: center;

		&--title {
			font: 24px $MuseosanscyrlB;
			margin-bottom: 10px;

			@media screen and (max-width: 1365px) {
				font-size: 18px;
				margin-bottom: 13px; }

			@media screen and (max-width: 767px) {
				margin-bottom: 10px; } }

		&--subtitle {
			font: 18px/26px $MuseosanscyrlR;
			padding: 0 15px;

			@media screen and (max-width: 1365px) {
				font-size: 14px;
				line-height: 20px;
				padding: 0 5px; }

			@media screen and (max-width: 1023px) {
				max-width: 210px;
				margin: 0 auto; }

			@media screen and (max-width: 579px) {
				padding: 0; } } } }
