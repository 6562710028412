@mixin placeholder($font, $color) {
    &::placeholder {
        color: $color;
        opacity: 1;
        font: $font; }

    &::-ms-placeholder {
        color: $color;
        opacity: 1;
        font: $font; }

    &:-ms-input-placeholder {
        color: $color;
        opacity: 1;
        font: $font; }

    &:focus:placeholder {
        opacity: 0; }

    &:focus::-ms-placeholder {
        opacity: 0; }

    &:focus:-ms-input-placeholder {
        opacity: 0; } }
