.footer {
	&__upper {
		border-bottom: 1px solid $lightGray;
		display: flex;
		padding-bottom: 5px;
		padding: 65px 70px 5px;

		@media screen and (max-width: 1919px) {
			padding: 65px 35px 5px; }

		@media screen and (max-width: 1365px) {
			padding: 65px 20px 5px 30px; }

		@media screen and (max-width: 1023px) {
			padding: 55px 10px 40px; }

		@media screen and (max-width: 767px) {
			padding: 50px 15px 10px; }

		@media screen and (max-width: 579px) {
			padding: 40px 20px 10px;
			border-bottom: none;
			max-width: 400px;
			margin: 0 auto; } }

	&__lower {
		display: flex;
		justify-content: space-between;
		padding: 30px 70px 40px;

		@media screen and (max-width: 1919px) {
			padding: 30px 35px 40px; }

		@media screen and (max-width: 1365px) {
			padding: 35px 35px 40px; }

		@media screen and (max-width: 1023px) {
			padding: 45px 0 55px 10px; }

		@media screen and (max-width: 767px) {
			flex-flow: column nowrap;
			padding: 30px 15px 50px; }

		@media screen and (max-width: 579px) {
			padding: 40px 20px 55px;
			max-width: 400px;
			margin: 0 auto; } }

	&__wrap-info {
		display: flex;
		align-items: center;

		@media screen and (max-width: 767px) {
			flex-flow: row wrap;
			justify-content: center;
			margin-bottom: 25px; } }

	&__img {
		margin-right: 20px;
		flex: 0 0 auto;

		@media screen and (max-width: 579px) {
			margin-right: 15px; }

		&--flag {
			width: 99px;
			height: 52px;

			@media screen and (max-width: 767px) {
				width: 86px;
				height: 45px;
				margin-left: 25px; }

			@media screen and (max-width: 579px) {
				width: 76px;
				height: 39px;
				margin-left: 0; } }

		&--seal {
			width: 73px;
			height: 73px;

			@media screen and (max-width: 767px) {
				width: 62px;
				height: 62px; }

			@media screen and (max-width: 579px) {
				width: 56px;
				height: 56px; } } }

	&__descrip {
		font: 13px/20px $MuseosanscyrlL;
		color: $black;
		padding-top: 5px;
		margin-right: 40px;

		@media screen and (max-width: 767px) {
			margin: 0 20px;
			text-align: center; }

		@media screen and (max-width: 579px) {
			font-size: 12px;
			line-height: 18px;
			padding-top: 10px;
			text-align: left;
			margin: 0; } } }
