.contacts {
	position: relative;

	@media screen and (max-width: 1023px) {
		display: flex;
		flex-flow: column nowrap; }

	&__wrap {
		position: absolute;
		max-width: 1920px;
		height: 100%;
		width: 100%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		@media screen and (max-width: 1919px) {
			max-width: 1366px; }

		@media screen and (max-width: 1365px) {
			max-width: 1024px; }

		@media screen and (max-width: 1023px) {
			max-width: 100%;
			width: 100%;
			position: static;
			transform: translateX(0);
			background: $darkBlue; } }

	&__info {
		position: absolute;
		background: $darkBlue;
		top: 170px;
		left: 150px;
		min-width: 810px;
		max-width: 910px;
		height: 645px;
		display: flex;
		flex-flow: column nowrap;
		padding: 100px 100px 110px;

		@media screen and (max-width: 1919px) {
			min-width: 625px;
			max-width: 700px;
			height: 520px;
			padding: 50px 75px;
			top: 90px;
			left: 60px; }

		@media screen and (max-width: 1365px) {
			min-width: 475px;
			max-width: 550px;
			height: 395px;
			top: 70px;
			left: 40px;
			padding: 40px 50px; }

		@media screen and (max-width: 1023px) {
			position: static;
			background: none;
			order: 1;
			max-width: 768px;
			height: auto;
			padding: 75px 50px;
			min-width: inherit;
			margin: 0 auto; }

		@media screen and (max-width: 767px) {
			padding: 60px 30px;
			max-width: 580px; }

		@media screen and (max-width: 579px) {
			padding: 45px 20px 40px; }

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 396px;
			height: 163px;
			background: url("../img/stars-blue-big.png") no-repeat;
			background-size: cover;

			@media screen and (max-width: 1919px) {
				width: 329px;
				height: 127px;
				background-size: 100% 100%; }

			@media screen and (max-width: 1023px) {
				display: none; } } }

	&__block {
		&--fair {
			margin: 100px 0 75px;

			@media screen and (max-width: 1919px) {
				margin: 120px 0 55px; }

			@media screen and (max-width: 1365px) {
				margin: 90px 0 40px; }

			@media screen and (max-width: 1023px) {
				margin: 0 0 35px; }

			@media screen and (max-width: 579px) {
				margin: 0 0 30px; } } }

	&__text {
		display: block;
		color: $white;

		@media screen and (max-width: 1023px) {
			text-align: center; }

		&--fair {
			font: 50px $MuseosanscyrlL;

			@media screen and (max-width: 1919px) {
				font-size: 40px; }

			@media screen and (max-width: 1365px) {
				font-size: 30px; }

			@media screen and (max-width: 767px) {
				font-size: 26px; }

			@media screen and (max-width: 579px) {
				font-size: 20px; } }

		&--data {
			font: 40px $MuseosanscyrlSB;

			@media screen and (max-width: 1919px) {
				font-size: 30px; }

			@media screen and (max-width: 1365px) {
				font-size: 20px; }

			@media screen and (max-width: 767px) {
				font-size: 18px; }

			@media screen and (max-width: 579px) {
				font-size: 16px; } }

		&--date {
			margin-bottom: 15px;

			@media screen and (max-width: 1919px) {
				margin-bottom: 10px; } }

		&--place {
			color: rgba(255, 255, 255, 0.5); }

		&--adress {
			margin-bottom: 30px;

			@media screen and (max-width: 1919px) {
				margin-bottom: 25px; }

			@media screen and (max-width: 1365px) {
				margin-bottom: 15px; } } } }
