.registration {
	position: relative;
	padding: 120px 0 130px;

	@media screen and (max-width: 1365px) {
		padding: 110px 0 115px; }

	@media screen and (max-width: 1023px) {
		padding: 80px 0 100px; }

	@media screen and (max-width: 767px) {
		padding: 70px 0 85px; }

	@media screen and (max-width: 579px) {
		padding: 60px 0 45px; }

	&:before {
		content: '';
		position: absolute;
		top: 0;
		top: 0;
		width: 50%;
		height: 100%;
		background: $lightBlue;
		z-index: -1;

		@media screen and (max-width: 579px) {
			display: none; } }

	&__wrap {
		position: relative;

		@media screen and (max-width: 1023px) {
			display: flex;
			flex-flow: column nowrap; } }
	&__subtitle.subtitle-second-row {
		font: 24px "Museosanscyrl500", sans-serif;
		max-width: 680px;
		margin: auto;
		margin-bottom: 20px;
		color: #1a5481;
		@media screen and (max-width: 767px) {
			font: 15px "Museosanscyrl500", sans-serif; } }
	&__subtitle {
		font: 36px $MuseosanscyrlR;
		color: $blue;
		text-align: center;
		padding-left: 5px;
		display: block;
		margin-bottom: 60px;

		@media screen and (max-width: 1365px) {
			font-size: 30px;
			margin-bottom: 50px; }

		@media screen and (max-width: 1023px) {
			font-size: 26px;
			margin-bottom: 25px;
			order: 2; }

		@media screen and (max-width: 767px) {
			font-size: 22px; }

		@media screen and (max-width: 579px) {
			font-size: 18px; } } }
