$MuseosanscyrlR: 'Museosanscyrl500', sans-serif;
$MuseosanscyrlL: 'Museo Sans Cyrl 300', sans-serif;
$MuseosanscyrlSB: 'Museo Sans Cyrl 700', sans-serif;
$MuseosanscyrlB: 'Museo Sans Cyrl 900', sans-serif;

@font-face {
	font-family: 'Museosanscyrl500';
	src: url('../fonts/Museosanscyrl500.eot');
	src: url('../fonts/Museosanscyrl500.eot') format('embedded-opentype'), url('../fonts/Museosanscyrl500.woff2') format('woff2'), url('../fonts/Museosanscyrl500.woff') format('woff'), url('../fonts/Museosanscyrl500.ttf') format('truetype'), url('../fonts/Museosanscyrl500.svg#Museosanscyrl500') format('svg');
	font-style: normal;
	font-weight: 500;
	font-display: swap; }

@font-face {
	font-family: 'Museo Sans Cyrl 300';
	src: url('../fonts/MuseoSansCyrl-300.eot');
	src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'), url('../fonts/MuseoSansCyrl-300.woff2') format('woff2'), url('../fonts/MuseoSansCyrl-300.woff') format('woff'), url('../fonts/MuseoSansCyrl-300.ttf') format('truetype'), url('../fonts/MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Museo Sans Cyrl 700';
	src: url('../fonts/MuseoSansCyrl-700.eot');
	src: url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'), url('../fonts/MuseoSansCyrl-700.woff2') format('woff2'), url('../fonts/MuseoSansCyrl-700.woff') format('woff'), url('../fonts/MuseoSansCyrl-700.ttf') format('truetype'), url('../fonts/MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
	font-weight: 700;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Museo Sans Cyrl 900';
	src: url('../fonts/MuseoSansCyrl-900.eot');
	src: url('../fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'), url('../fonts/MuseoSansCyrl-900.woff2') format('woff2'), url('../fonts/MuseoSansCyrl-900.woff') format('woff'), url('../fonts/MuseoSansCyrl-900.ttf') format('truetype'), url('../fonts/MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap; }
