.burger {
	display: none;

	@media screen and (max-width: 767px) {
		display: block;
		width: 26px;
		height: 14px;
		cursor: pointer;
		position: relative;
		border-top: 1px solid $darkBlue;
		border-bottom: 1px solid $darkBlue; }

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 1px;
		transform: translate(-50%, -50%);
		transition: transform .25s ease-in;
		background: $darkBlue; }

	&--active {
		border-top: none;
		border-bottom: none;

		&:before {
			transform: translate(-50%, -50%) rotate(45deg); }

		&:after {
			transform: translate(-50%, -50%) rotate(-45deg); } } }
