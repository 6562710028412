.container {
	padding: 0 15px;
	margin: 0 auto;

	@media screen and (max-width: 579px) {
		padding: 0 20px; }

	&--wait-for-you {
		width: 1330px;

		@media screen and (max-width: 1365px) {
			width: 996px; }

		@media screen and (max-width: 1023px) {
			width: 740px; }

		@media screen and (max-width: 767px) {
			width: 550px; }

		@media screen and (max-width: 579px) {
			width: 100%; } }

	&--wide {
		width: 1796px;

		@media screen and (max-width: 1919px) {
			width: 1280px; }

		@media screen and (max-width: 1365px) {
			width: 980px; }

		@media screen and (max-width: 1023px) {
			width: 738px; }

		@media screen and (max-width: 767px) {
			width: 550px; }

		@media screen and (max-width: 579px) {
			width: 100%; } } }
