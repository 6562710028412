.background {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	overflow: auto;
	background: transparent;
	transition: z-index .5s linear, background .5s linear;

	&--open {
		z-index: 100;
		background: rgba(0, 0, 0, 0.5); } }
