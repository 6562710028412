.calendar {
	position: absolute;
	top: 305px;
	right: 255px;
	width: 150px;
	cursor: pointer;

	@media screen and (max-width: 1919px) {
		top: 305px;
		right: 0; }

	@media screen and (max-width: 1365px) {
		top: 245px; }

	@media screen and (max-width: 1023px) {
		position: static;
		order: 3;
		margin: 0 auto 35px; }

	@media screen and (max-width: 579px) {
		width: 100%;
		max-width: 400px;
		margin: 0 auto 25px; }

	&__item {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 13px 0;
		transition: color .35s ease-in;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:hover {
			.calendar__text {
				color: $red; }

			.icon__item--triangle {
				fill: $red; } } }

	&__text {
		color: $darkBlue;
		font: 13px $MuseosanscyrlSB;

		@media screen and (max-width: 579px) {
			font-size: 12px; } }

	&__wrap {
		display: none; } }
