.textarea {
	color: $black;
	width: 100%;
	height: 190px;
	background-color: transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px 0;
	font: 18px $MuseosanscyrlL;
	@include placeholder(18px $MuseosanscyrlL, rgba(0, 0, 0, 0.3));
	resize: none;
	overflow: auto;

	@media screen and (max-width: 1023px) {
		font: 16px $MuseosanscyrlL;
		@include placeholder(16px $MuseosanscyrlL, rgba(0, 0, 0, 0.3));
		height: 180px; }

	@media screen and (max-width: 767px) {
		font: 14px $MuseosanscyrlL;
		@include placeholder(14px $MuseosanscyrlL, rgba(0, 0, 0, 0.3));
		padding: 10px 0;
		height: 140px; }

	@media screen and (max-width: 579px) {
		height: 120px; } }
