.social {
	width: 50%;
	padding-left: 130px;
	position: relative;

	@media screen and (max-width: 1919px) {
		padding-left: 150px;
		width: auto; }

	@media screen and (max-width: 1365px) {
		padding-left: 80px; }

	@media screen and (max-width: 1023px) {
		padding-left: 0;
		width: 100%; }

	&:before {
		content: '';
		position: absolute;
		top: -20px;
		left: 0;
		width: 1px;
		height: 225px;
		background: $lightGray;

		@media screen and (max-width: 1365px) {
			height: 200px; }

		@media screen and (max-width: 1023px) {
			display: none; } }

	&__list {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 65px;
			left: 0;
			width: 530px;
			height: 1px;
			background: $lightGray;

			@media screen and (max-width: 1365px) {
				top: 50px;
				width: 470px; }

			@media screen and (max-width: 1023px) {
				display: none; } } }

	&__item {
		width: 190px;
		margin-bottom: 55px;

		@media screen and (max-width: 1365px) {
			width: 150px;
			margin-bottom: 45px; }

		@media screen and (max-width: 1023px) {
			width: 20%;
			margin-bottom: 0; }

		@media screen and (max-width: 767px) {
			width: 33.3%;
			margin-bottom: 30px; }

		@media screen and (max-width: 579px) {
			width: 50%; } }

	&__link {
		display: inline-flex;
		align-items: center;

		&:hover {
			.social__text {
				color: $red; }

			.icon__item {
				fill: $red; } } }

	&__text {
		color: $darkBlue;
		font: 14px $MuseosanscyrlR;
		padding-left: 25px;
		padding-top: 5px;
		transition: color .35s ease-in;

		@media screen and (max-width: 1365px) {
			font-size: 12px; }

		@media screen and (max-width: 1023px) {
			padding-top: 0;
			padding-left: 15px; }

		&--tg {
			padding-left: 15px; } } }
