html,body {
    width: 100%;
    height: 100%; }

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,
blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,
em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,
var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,
table,caption,tbody,tfoot,thead,tr,th,td,button,figure {
    margin: 0;
    padding: 0;
    outline: 0;
    font-size: 100%;
    background: transparent;
    border: 0; }

tbody,tfoot,thead,tr,th,td {
    vertical-align: top; }

h1,h2,h3,h4,h5,h6 {
    font-weight: inherit; }

ul,li {
    list-style: none; }

blockquote, q {
    quotes: none;

    &:before, &:after {
        content: '';
        content: none; } }

input, textarea, select {
    padding: 0;
    margin: 0;
    border: none;

    &:focus {
        outline: 0 !important; } }

ins {
    text-decoration: none; }

del {
    text-decoration: line-through; }

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%; }

th {
    font-weight: inherit;
    text-align: left; }

a {
    display: block; }

a,a:focus,a:hover,a:active {
    cursor: pointer;
    text-decoration: none; }

button {
    border: none;
    cursor: pointer;
    background-color: transparent; }

img {
    width: 100%;
    height: auto; }

* {
    outline: none; }

*,:after,:before {
    box-sizing: border-box; }

a::selection {
    background-color: transparent; }
