.gallery {
	display: flex;
	flex-flow: row wrap;
	overflow: hidden;

	@media screen and (max-width: 767px) {
		width: 520px;
		margin-bottom: 30px; }

	@media screen and (max-width: 579px) {
		width: 100%;
		max-width: 400px;
		margin-bottom: 15px;
		height: 160px; }

	&__link {
		width: 50%;
		height: 495px;

		@media screen and (max-width: 1919px) {
			height: 350px; }

		@media screen and (max-width: 1365px) {
			height: 265px; }

		@media screen and (max-width: 1023px) {
			height: 200px; }

		@media screen and (max-width: 767px) {
			height: 265px;
			width: 100%; }

		@media screen and (max-width: 579px) {
			height: 160px;
			width: 400px; }

		&:first-child {
			margin-left: 50%;

			@media screen and (max-width: 767px) {
				margin-left: 0; } }

		&--small {
			width: 33%;
			flex: 1 0 auto;
			max-width: 590px;
			height: 330px;

			@media screen and (max-width: 1919px) {
				height: 235px; }

			@media screen and (max-width: 1365px) {
				height: 177px; }

			@media screen and (max-width: 1023px) {
				height: 133px; }

			@media screen and (max-width: 767px) {
				height: 265px;
				width: 100%; }

			@media screen and (max-width: 579px) {
				height: 160px; } } }

	&__item {
		height: 100%;

		@media screen and (max-width: 767px) {
			width: 100%; } } }
