.input {
	color: $black;
	width: 100%;
	background-color: transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px 0;

	&--registration {
		font: 18px $MuseosanscyrlR;
		@include placeholder(18px $MuseosanscyrlR, rgba(0, 0, 0, 0.5));

		@media screen and (max-width: 1365px) {
			font-size: 16px;
			@include placeholder(16px $MuseosanscyrlR, rgba(0, 0, 0, 0.5));
			padding: 14px 0; }

		@media screen and (max-width: 579px) {
			font-size: 12px;
			@include placeholder(12px $MuseosanscyrlR, rgba(0, 0, 0, 0.5));
			padding: 11px 0; } }

	&--modal {
		font: 18px $MuseosanscyrlL;
		@include placeholder(18px $MuseosanscyrlL, rgba(0, 0, 0, 0.3));

		@media screen and (max-width: 1023px) {
			font: 16px $MuseosanscyrlL;
			@include placeholder(16px $MuseosanscyrlL, rgba(0, 0, 0, 0.3)); }

		@media screen and (max-width: 767px) {
			font: 14px $MuseosanscyrlL;
			@include placeholder(14px $MuseosanscyrlL, rgba(0, 0, 0, 0.3));
			padding: 12px 0; }

		@media screen and (max-width: 579px) {
			padding: 10px 0; } }

	&--checkbox {
		display: none;

		&:checked + label:after {
			opacity: 1; } } }
