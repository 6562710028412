.indent {
	&--title {
		&-wait-for-you {
			margin-bottom: 65px;

			@media screen and (max-width: 1365px) {
				margin-bottom: 60px; }

			@media screen and (max-width: 767px) {
				margin-bottom: 35px; } }

		&-program {
			margin-bottom: 45px;

			@media screen and (max-width: 1365px) {
				margin-bottom: 35px; } } }

	&--checkbox {
		&-registr {
			margin-bottom: 45px;

			@media screen and (max-width: 1365px) {
				margin-bottom: 40px; }

			@media screen and (max-width: 579px) {
				margin-bottom: 20px; } }

		&-modal {
			margin-bottom: 60px;

			@media screen and (max-width: 767px) {
				margin-bottom: 35px; }

			@media screen and (max-width: 579px) {
				margin-bottom: 20px; } } } }
