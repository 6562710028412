.header {
	display: flex;
	justify-content: flex-end;
	border-bottom: 1px solid $lightGray;

	@media screen and (max-width: 1023px) {
		flex-flow: column nowrap; }

	&__side {
		width: 50%;

		@media screen and (max-width: 1023px) {
			width: 100%; }

		&--left {
			padding: 20px 50px 130px 80px;

			@media screen and (max-width: 1919px) {
				padding: 20px 40px 60px 60px;
				max-width: 683px;
				margin: 0 auto; }

			@media screen and (max-width: 1365px) {
				padding: 15px 35px 40px 40px;
				max-width: 512px; }

			@media screen and (max-width: 1023px) {
				padding: 15px 30px 55px;
				max-width: 768px; }

			@media screen and (max-width: 767px) {
				padding: 10px 20px 45px; } }

		&--right {
			@media screen and (max-width: 1023px) {
				height: 785px; }

			@media screen and (max-width: 767px) {
				height: 480px; }

			@media screen and (max-width: 579px) {
				height: 330px; } } }

	&__nav {
		z-index: 40;

		&--header {
			display: flex;
			margin-bottom: 85px;
			border-bottom: 1px solid $lightGray;
			padding-bottom: 10px;

			@media screen and (max-width: 1919px) {
				margin-bottom: 70px; }

			@media screen and (max-width: 1365px) {
				margin-bottom: 30px; }

			@media screen and (max-width: 1023px) {
				margin-bottom: 55px;
				padding-bottom: 15px; }

			@media screen and (max-width: 767px) {
				padding-bottom: 10px;
				margin-bottom: 0;
				align-items: center;
				justify-content: space-between;
				position: fixed;
				top: 0;
				left: 50%;
				width: 100%;
				border: none;
				transform: translateX(-50%);
				background: $white;
				padding-bottom: 0; }

			&-fix {
				@media screen and (max-width: 767px) {
					box-shadow: 0px 4px 15px rgba(0, 54, 95, 0.1); } } }

		&--fixed {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			display: none;
			background: $white;
			padding-bottom: 0;
			border: none;
			margin: 0;
			box-shadow: 0px 4px 15px rgba(0, 54, 95, 0.1); } }

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin: 0 auto;

		&--header {
			@media screen and (max-width: 767px) {
				max-width: 580px;
				padding: 10px 20px;
				border-bottom: 1px solid $lightGray; }

			@media screen and (max-width: 579px) {
				max-width: 100%; } }

		&--fixed {
			padding: 10px 80px;
			max-width: 1920px;

			@media screen and (max-width: 1919px) {
				max-width: 1366px;
				padding: 10px 60px; }

			@media screen and (max-width: 1365px) {
				max-width: 1024px;
				padding: 10px 40px; }

			@media screen and (max-width: 1023px) {
				max-width: 768px;
				padding: 10px 30px; }

			@media screen and (max-width: 767px) {
				display: none; } } }

	&__span {
		font: 20px $MuseosanscyrlL;
		color: $black;
		display: inline-block;
		margin-bottom: 35px;

		@media screen and (max-width: 1919px) {
			font-size: 18px; }

		@media screen and (max-width: 1365px) {
			font-size: 14px;
			margin-bottom: 25px; }

		@media screen and (max-width: 1023px) {
			width: 100%;
			text-align: center; }

		@media screen and (max-width: 767px) {
			margin: 0 auto 25px;
			display: block;
			max-width: 240px; }

		@media screen and (max-width: 579px) {
			font-size: 12px;
			max-width: 200px; } }

	&__title-wrap {
		margin-bottom: 165px;

		@media screen and (max-width: 1919px) {
			margin-bottom: 75px; }

		@media screen and (max-width: 1365px) {
			margin-bottom: 50px; }

		@media screen and (max-width: 1023px) {
			margin-bottom: 55px; }

		@media screen and (max-width: 767px) {
			margin-bottom: 45px;
			margin-top: 90px; } }

	&__img {
		height: 100%; } }
