.label {
	position: relative;

	&--registration:not(:last-child) {
		margin-bottom: 25px;

		@media screen and (max-width: 1365px) {
			margin-bottom: 20px; }

		@media screen and (max-width: 579px) {
			margin-bottom: 17px; } }

	&--modal:not(:last-child) {
		margin-bottom: 50px;

		@media screen and (max-width: 1023px) {
			margin-bottom: 35px; }

		@media screen and (max-width: 767px) {
			margin-bottom: 20px; }

		@media screen and (max-width: 579px) {
			margin-bottom: 12px; } }

	&--checkbox {
		font: 14px/17px $MuseosanscyrlR;
		position: relative;
		padding-left: 40px;
		display: inline-block;
		cursor: pointer;

		@media screen and (max-width: 1023px) {
			padding-left: 45px; }

		@media screen and (max-width: 579px) {
			padding-left: 40px;
			margin-right: 0; }

		&-registration {
			color: $blueGray;

			@media screen and (max-width: 1365px) {
				font-size: 12px;
				line-height: 14px;
				margin-right: 20px; }

			@media screen and (max-width: 579px) {
				font-size: 10px;
				line-height: 12px;
				margin-right: 0; } }

		&-modal {
			color: $black;

			@media screen and (max-width: 1023px) {
				font-size: 12px;
				line-height: 14px; }

			@media screen and (max-width: 579px) {
				font-size: 10px;
				line-height: 12px; } }

		&:before {
			content: '';
			position: absolute;
			top: 2px;
			left: 0;
			width: 26px;
			height: 26px;
			border-radius: 50%;
			border: 1px solid $blueGray; }

		&:after {
			content: '';
			position: absolute;
			top: 4px;
			left: 8px;
			height: 17px;
			width: 21px;
			background: url("../img/check.svg") no-repeat;
			background-size: cover;
			transition: opacity .25s ease-in;
			opacity: 0; } } }
