.slider {
	position: relative;

	&__item {
		&--university {
			position: absolute;

			@media screen and (max-width: 579px) {
				max-width: 400px;
				left: 50%;
				transform: translateX(-50%); } } }

	&__img {
		width: 1290px;
		height: 725px;
		position: absolute;
		top: 0;
		left: 0;

		@media screen and (max-width: 1919px) {
			width: 920px;
			height: 520px; }

		@media screen and (max-width: 1365px) {
			width: 700px;
			height: 395px; }

		@media screen and (max-width: 1023px) {
			width: 100%; }

		@media screen and (max-width: 579px) {
			height: 155px; } }

	&__info {
		background: $white;
		width: 640px;
		height: 540px;
		position: absolute;
		top: 60px;
		right: 0;
		padding: 65px 75px 50px;
		display: flex;
		flex-flow: column nowrap;
		box-shadow: 0px 10px 20px rgba(26, 84, 130, 0.08);
		z-index: 10;

		@media screen and (max-width: 1919px) {
			top: 50px;
			width: 500px;
			height: 365px;
			padding: 40px 45px; }

		@media screen and (max-width: 1365px) {
			top: 30px;
			width: 375px;
			height: 285px;
			padding: 30px 25px 25px; }

		@media screen and (max-width: 1023px) {
			top: 325px;
			right: 180px;
			width: 465px;
			height: 250px;
			padding: 30px 35px 25px; }

		@media screen and (max-width: 767px) {
			width: 100%;
			height: 200px;
			right: 0;
			top: 395px;
			padding: 33px 30px 25px 30px; }

		@media screen and (max-width: 579px) {
			top: 155px;
			height: 290px;
			padding: 23px 10px 25px 15px; } }

	&__text {
		color: $black;
		font: 20px/30px $MuseosanscyrlR;
		flex: 1 0 auto;
		margin-bottom: 20px;

		@media screen and (max-width: 1919px) {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 15px; }

		@media screen and (max-width: 1365px) {
			font-size: 12px;
			line-height: 18px; }

		@media screen and (max-width: 767px) {
			margin-bottom: 0; } }

	&__count {
		color: $blueGray;
		font: 24px $MuseosanscyrlL;

		@media screen and (max-width: 1919px) {
			font-size: 18px; }

		@media screen and (max-width: 1365px) {
			font-size: 16px; }

		@media screen and (max-width: 579px) {
			font-size: 14px; }

		&--active {
			&-gallery {
				color: $white; }

			&-university {
				color: $darkBlue; } }

		&--separ {
			padding: 0 5px; } }

	&__control {
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;

		@media screen and (max-width: 767px) {
			position: static;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%; }

		@media screen and (max-width: 579px) {
			max-width: 400px;
			margin: 0 auto; }

		&--university {
			@media screen and (max-width: 1023px) {
				bottom: 40px; }

			@media screen and (max-width: 767px) {
				bottom: 20px; } }

		&--gallery {
			display: none;

			@media screen and (max-width: 767px) {
				bottom: -85px;
				display: flex; }

			@media screen and (max-width: 579px) {
				bottom: -55px; } } }

	&__arrow {
		width: 72px;
		height: 72px;
		background: $white;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		position: relative;
		transition: background .25s ease-in;

		@media screen and (max-width: 1365px) {
			width: 60px;
			height: 60px; }

		@media screen and (max-width: 767px) {
			width: 50px;
			height: 50px; }

		@media screen and (max-width: 579px) {
			width: 40px;
			height: 40px; }

		&--university {
			border: 1px solid $lightGray; }

		&--gallery {
			border: 1px solid $blue; }

		&:hover,
		&:active {
			background: $blue;

			.icon__item {
				stroke: $white; }

			&:before {
				opacity: 0; } }

		&--university {
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 56px;
				height: 56px;
				border-radius: 50%;
				border: 1px solid rgba(255, 255, 255, 0.1);
				opacity: 1;
				transition: opacity .25s ease-in;

				@media screen and (max-width: 1365px) {
					width: 47px;
					height: 47px; }

				@media screen and (max-width: 767px) {
					width: 37px;
					height: 37px; }

				@media screen and (max-width: 579px) {
					width: 31px;
					height: 31px; } } }

		&--next {
			margin-left: 20px;
			transform: rotate(180deg);

			@media screen and (max-width: 767px) {
				margin-left: 0; } }

		&--active {
			background: $darkBlue;

			.icon__item {
				stroke: $white; } } }

	&__wrap-count {
		&--univer-content {
			@media screen and (max-width: 767px) {
				display: none; } }

		&--control {
			display: none;

			@media screen and (max-width: 767px) {
				display: block; } } } }
