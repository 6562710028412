.developer {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	margin-right: 190px;
	margin-top: 10px;

	@media screen and (max-width: 1919px) {
		margin-right: 95px; }

	@media screen and (max-width: 1365px) {
		margin-right: 0;
		margin-top: 0;
		padding-bottom: 10px; }

	@media screen and (max-width: 1023px) {
		margin-top: 10px;
		padding-bottom: 0; }

	@media screen and (max-width: 767px) {
		margin-top: 0;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center; }

	@media screen and (max-width: 579px) {
		justify-content: flex-start; }

	&:hover {
		.developer__text {
			color: rgba(0, 0, 0, 0.8); }

		.icon__item--developer {
			fill: #000; } }

	&__text {
		font: 13px $MuseosanscyrlL;
		margin-bottom: 10px;
		color: rgba(0, 0, 0, 0.4);
		transition: color .25s ease-in;

		@media screen and (max-width: 767px) {
			margin-bottom: 0;
			padding-top: 5px;
			margin-right: 20px; } } }
