.how-it-was {
	background: $darkBlue;
	padding: 110px 0 140px;

	@media screen and (max-width: 1919px) {
		padding: 80px 0 110px; }

	@media screen and (max-width: 1365px) {
		padding: 65px 0 85px; }

	@media screen and (max-width: 1023px) {
		padding: 60px 0 75px; }

	@media screen and (max-width: 767px) {
		padding: 55px 0; }

	@media screen and (max-width: 579px) {
		padding: 55px 0 60px; }

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 0;

		@media screen and (max-width: 767px) {
			flex-flow: column nowrap; }

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: -75px;
			width: 302px;
			height: 224px;
			background: url("../img/stars-blue.png") no-repeat;
			background-size: cover;

			@media screen and (max-width: 1919px) {
				width: 221px;
				height: 164px;
				left: -60px; }

			@media screen and (max-width: 1365px) {
				left: -30px;
				height: 130px;
				width: 198px;
				background-size: 221px 164px; }

			@media screen and (max-width: 1023px) {
				left: -55px;
				height: 90px;
				width: 180px;
				background-position: right; }

			@media screen and (max-width: 767px) {
				display: none; } } } }
