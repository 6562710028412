.logo {
	display: inline-block;
	flex: 0 0 auto;

	&--header {
		width: 90px;
		height: 86px;
		margin-right: 50px;
		position: relative;

		@media screen and (max-width: 1919px) {
			width: 74px;
			height: 71px;
			margin-right: 40px; }

		@media screen and (max-width: 1365px) {
			width: 56px;
			height: 53px;
			margin-right: 30px; }

		@media screen and (max-width: 767px) {
			width: 38px;
			height: 36px; }

		&:before {
			content: '';
			position: absolute;
			top: -5px;
			right: -50px;
			width: 1px;
			height: 95px;
			background: $lightGray;

			@media screen and (max-width: 1919px) {
				height: 80px;
				right: -40px; }

			@media screen and (max-width: 1365px) {
				right: -30px;
				height: 60px; }

			@media screen and (max-width: 767px) {
				display: none; } } }

	&--footer {
		width: 93px;
		height: 42px;
		margin-right: 25px;

		@media screen and (max-width: 767px) {
			width: 84px;
			height: 38px;
			margin-right: 0; }

		@media screen and (max-width: 579px) {
			width: 71px;
			height: 32px; } }

	&--developer {
		width: 120px;
		height: 21px; }

	&--fixed {
		width: 60px;
		height: 56px;
		margin-right: 50px;
		position: relative;

		@media screen and (max-width: 1919px) {
			width: 49px;
			height: 46px; }

		@media screen and (max-width: 1365px) {
			width: 41px;
			height: 38px;
			margin-right: 30px; }

		&:before {
			content: '';
			position: absolute;
			top: -5px;
			right: -50px;
			width: 1px;
			height: 65px;
			background: $lightGray;

			@media screen and (max-width: 1919px) {
				height: 55px; }

			@media screen and (max-width: 1365px) {
				right: -30px;
				height: 45px; } } } }

.image__icon--logo {
	width: auto;
	display: flex;
	align-items: center; }

.image__logo--header {
	width: 125px;
	height: 86px;
	margin-right: 20px;
	position: relative;
	@media screen and (max-width: 767px) {
		width: 85px;
		height: 45px; } }
