.university {
	padding: 135px 0 155px;

	@media screen and (max-width: 1919px) {
		padding: 125px 0 150px; }

	@media screen and (max-width: 1365px) {
		padding: 115px 0 140px; }

	@media screen and (max-width: 1023px) {
		padding: 110px 0 80px; }

	@media screen and (max-width: 767px) {
		padding: 70px 0;
		position: relative; }

	@media screen and (max-width: 579px) {
		padding: 45px 0 55px; } }
