.btn {
	&--main {
		width: 0;
		min-width: 350px;
		background: $red;
		color: $white;
		font: 18px/22px $MuseosanscyrlSB;
		border-radius: 130px;
		padding: 14px 15px;
		text-align: center;
		box-shadow: 0 5px 10px rgba(215, 9, 38, 0.2);
		transition: box-shadow .25s ease-in, background .25s ease-in;

		@media screen and (max-width: 1023px) {
			margin: 0 auto; }

		@media screen and (max-width: 767px) {
			font-size: 14px;
			line-height: 17px;
			min-width: 250px;
			padding: 11px 15px; }

		&:hover {
			box-shadow: 0 5px 10px transparent, inset 0 0 0 7px $red, inset 0 0 0 8px rgba(255, 255, 255, 0.5); }

		&:active {
			box-shadow: none; } }

	&--program {
		margin: 0 auto;
		transform: translateX(-25px);

		@media screen and (max-width: 1365px) {
			transform: translateX(15px); }

		@media screen and (max-width: 1023px) {
			margin: 0;
			transform: translateX(0); }

		@media screen and (max-width: 767px) {
			margin: 0 auto; } }

	&--registration {
		box-shadow: none;
		display: block;
		margin: 0 auto;
		font: 16px/19px $MuseosanscyrlR;

		@media screen and (max-width: 767px) {
			font-size: 14px;
			line-height: 17px; }

		&:hover {
			box-shadow: 0 5px 10px rgba(215, 9, 38, 0.2), inset 0 0 0 7px $red, inset 0 0 0 8px rgba(255, 255, 255, 0.5); }

		&:active {
			box-shadow: 0 5px 10px transparent, inset 0 0 0 7px $red, inset 0 0 0 8px rgba(255, 255, 255, 0.5); } }

	&--modal {
		box-shadow: none;
		display: block;
		margin: 0 auto;
		background: $blue;
		font: 16px/19px $MuseosanscyrlR;

		@media screen and (max-width: 579px) {
			font-size: 14px;
			line-height: 17px;
			min-width: 140px;
			padding: 10px 15px; }

		&:hover {
			background: $darkBlue;
			box-shadow: 0px 10px 10px rgba(0, 54, 95, 0.1); }

		&:active {
			box-shadow: none; } }

	&:disabled {
		background: $deepGray;
		cursor: default;

		&:hover {
			box-shadow: none; } } }
