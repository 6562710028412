.program {
	border-top: 1px solid $lightGray;
	border-bottom: 1px solid $lightGray;
	padding: 85px 0 150px;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 1365px) {
		padding: 75px 0 110px; }

	@media screen and (max-width: 1023px) {
		padding: 75px 0 105px; }

	@media screen and (max-width: 767px) {
		padding: 60px 0 80px; }

	@media screen and (max-width: 579px) {
		padding: 45px 0 55px; }

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;
		background: $darkBlue;
		z-index: -1;

		@media screen and (max-width: 579px) {
			display: none; } }

	&__content {
		padding: 0 260px;
		margin-right: 110px;

		@media screen and (max-width: 1919px) {
			padding: 0; }

		@media screen and (max-width: 1365px) {
			margin-right: 85px; }

		@media screen and (max-width: 1023px) {
			margin-right: 0; } }

	&__side {
		&--left {
			margin-right: 75px;

			@media screen and (max-width: 1365px) {
				margin-right: 40px; }

			@media screen and (max-width: 1023px) {
				margin-right: 0;
				margin-bottom: 45px; }

			@media screen and (max-width: 767px) {
				margin-bottom: 35px; }

			@media screen and (max-width: 579px) {
				margin-bottom: 30px; } }

		&--right {
			@media screen and (max-width: 1023px) {
				margin-bottom: 40px; }

			@media screen and (max-width: 767px) {
				margin-bottom: 30px; }

			@media screen and (max-width: 579px) {
				margin-bottom: 25px; } } }

	&__event {
		width: 370px;
		margin-bottom: 30px;

		@media screen and (max-width: 1365px) {
			margin-bottom: 27px;
			width: 335px; }

		@media screen and (max-width: 1023px) {
			width: 100%; }

		@media screen and (max-width: 767px) {
			margin-bottom: 22px; }

		@media screen and (max-width: 579px) {
			padding-right: 10px; }

		&:last-child {
			@media screen and (max-width: 1023px) {
				margin-bottom: 0; } } }

	&__section {
		background: $white;
		padding: 65px 110px 75px;
		position: relative;
		box-shadow: 0px 10px 20px rgba(26, 84, 130, 0.08);

		@media screen and (max-width: 1919px) {
			padding: 70px 115px; }

		@media screen and (max-width: 1365px) {
			padding: 70px 85px 65px; }

		@media screen and (max-width: 1023px) {
			padding: 55px 55px 60px 60px; }

		@media screen and (max-width: 767px) {
			padding: 45px 30px 55px 35px; }

		@media screen and (max-width: 579px) {
			padding: 35px 15px 45px 25px;
			max-width: 400px;
			margin: 0 auto; }

		&:before {
			content: '';
			position: absolute;
			top: -105px;
			right: -160px;
			width: 220px;
			height: 165px;
			background: url("../img/stars-blue.png") no-repeat;
			background-size: cover;
			z-index: -1;

			@media screen and (max-width: 1365px) {
				top: -82px;
				right: -170px; }

			@media screen and (max-width: 1023px) {
				display: none; } } }

	&__wrap {
		display: flex;
		margin-bottom: 5px;

		@media screen and (max-width: 1365px) {
			margin-bottom: 0; }

		@media screen and (max-width: 1023px) {
			flex-flow: column nowrap; } }

	&__time {
		color: $darkBlue;
		display: block;
		margin-bottom: 10px;
		font: 28px $MuseosanscyrlB;

		@media screen and (max-width: 1365px) {
			font-size: 24px; }

		@media screen and (max-width: 767px) {
			font-size: 22px; }

		@media screen and (max-width: 579px) {
			font-size: 20px; } }

	&__descrip {
		color: $black;
		font: 18px/28px $MuseosanscyrlR;

		@media screen and (max-width: 1365px) {
			font-size: 16px;
			line-height: 24px; }

		@media screen and (max-width: 767px) {
			font-size: 14px;
			line-height: 22px; }

		@media screen and (max-width: 579px) {
			font-size: 12px;
			line-height: 18px; } } }
