.date {
	color: $blue;
	font: 36px $MuseosanscyrlSB;
	display: block;
	margin-bottom: 15px;

	@media screen and (max-width: 1919px) {
		font-size: 28px;
		margin-bottom: 5px; }

	@media screen and (max-width: 1365px) {
		font-size: 24px; }

	@media screen and (max-width: 1023px) {
		text-align: center; }

	@media screen and (max-width: 767px) {
		margin-bottom: 10px;
		font-size: 20px; }

	@media screen and (max-width: 579px) {
		font-size: 18px; } }
